@import '../../../styles/customMediaQueries.css';

.root {
  composes: marketplaceModalFormRootStyles from global;

  justify-content: flex-start;

  @media (--viewportMedium) {
    justify-content: space-between;
  }
}

.password {
  composes: marketplaceModalPasswordMargins from global;
}

.bottomWrapper {
  composes: marketplaceModalBottomWrapper from global;

  & .submitBtn {
    max-width: 70%;
    width: 100%;
    margin: 0 auto;
    background-color: var(--marketplaceColorSecondary);
    font-size: 16px;
    line-height: 19px;
    font-weight: var(--fontWeightMedium);
    letter-spacing: -0.266667px;
    color: var(--colorWhite);
    &:hover {
      background-color: var(--marketplaceColor);
    }
    &:disabled {
      color: var(--colorGrey300);
      background-color: var(--colorGrey100);
      &:hover {
        background-color: var(--colorGrey100);
      }
    }
  }
}

/* Adjust the bottomWrapper's margin to match modals without the helper text */
.bottomWrapperText {
  composes: marketplaceModalBottomWrapperText from global;
}

.recoveryLink {
  composes: marketplaceModalHelperLink from global;
}

.recoveryLinkInfo {
  composes: marketplaceModalHelperText from global;
}

.loginForm {
  & .authHead {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0px 0 30px;

    @media (--viewportSmall) {
      margin: 0px 0 44px 0;
    }

    @media (--viewportMedium) {
      margin: 0px 0 44px 0;
    }
    & > h2 {
      font-weight: var(--fontWeightSemiBold);
      font-size: 20px;
      line-height: 24px;
      color: var(--colorBlack);
      text-align: center;
      margin: 0 0 10px 0;
      padding: 0;

      @media (min-width: 480px) {
        font-size: 24px;
        line-height: 30px;
      }

      @media (--viewportSmall) {
        font-size: 30px;
        line-height: 38px;
      }

      @media (--viewportMedium) {
        font-size: 36px;
        line-height: 48px;
      }
    }
    & > p {
      padding: 0;
      margin: 0;
      font-size: 16px;
      font-weight: var(--fontWeightRegular);
      color: var(--colorGrey600);
    }
  }
  & .formRow {
    display: flex;
    flex-direction: column;
    @media (--viewportSmall) {
      flex-direction: row;
    }
    &:last-child {
      & .formFld {
        margin-bottom: 0;
      }
    }
    &.fullWidth {
      flex-direction: column;
      & .formFld {
        width: 100%;
        margin-right: 0;
      }
      & .note {
        margin: 0;
        font-weight: var(--fontWeightMedium);
        font-size: 11px;
        line-height: 16px;
        letter-spacing: -0.2px;
        color: #888888;
      }
    }
    &.nameRow {
      & .formFld {
        width: calc(100% / 1);

        @media (--viewportSmall) {
          width: calc(100% / 2);
        }
        &:first-child {
          width: 100%;
          @media (--viewportSmall) {
            width: 26%;
          }
        }
      }
    }
    & .formFld {
      width: 100%;
      margin-bottom: 24px;
      position: relative;
      @media (--viewportSmall) {
        width: calc(100% / 2 - 12px);
        margin-right: 24px;
      }
      &:last-child {
        margin-right: 0;
      }
      & input,
      & select {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.304706px;
        color: #4a4a4a;
        min-height: 41px;
        box-shadow: none;
        &::placeholder {
          opacity: 1;
          color: #4a4a4a;
        }
      }
      & .showHidePass {
        position: absolute;
        right: 12px;
        z-index: 1;
        top: 6px;
        cursor: pointer;
        & > svg {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
}

.alreadyHaveAccount {
  font-weight: var(--fontWeightRegular);
  font-size: 16px;
  line-height: 19px;
  color: var(--colorBlack);
  & > a {
    font-weight: var(--fontWeightHeavy);
  }
}
