@import '../../../styles/customMediaQueries.css';

.root {
  composes: marketplaceModalFormRootStyles from global;

  justify-content: flex-start;

  @media (--viewportMedium) {
    justify-content: space-between;
  }
}

.name {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 32px;
  }
}

.bottomWrapper {
  composes: marketplaceModalBottomWrapper from global;

  & .terms {
    margin-bottom: 30px;
  }

  & .submitBtn {
    max-width: 70%;
    width: 100%;
    margin: 0 auto 20px;
    background-color: var(--marketplaceColorSecondary);
    font-size: 16px;
    line-height: 19px;
    font-weight: var(--fontWeightMedium);
    letter-spacing: -0.266667px;
    color: var(--colorWhite);

    &:hover {
      background-color: var(--marketplaceColor);
    }

    &:disabled {
      color: var(--colorGrey300);
      background-color: var(--colorGrey100);

      &:hover {
        background-color: var(--colorGrey100);
      }
    }
  }
}

.alreadyHaveAccount {
  font-weight: var(--fontWeightRegular);
  font-size: 16px;
  line-height: 19px;
  color: var(--colorBlack);

  & > a {
    font-weight: 600;
    text-decoration: underline;
  }
}

.alreadyHaveAccountLink {
  display: inline;
  margin: 0;
  padding: 0;
  border: none;
  color: var(--marketplaceColorSecondary);
  font-weight: 600;
  text-decoration: underline;
  cursor: pointer;
}

.alreadyHaveAccountLink:hover {
  color: var(--marketplaceColor);
}

.signupForm {
  & .authHead {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0px 0 30px;

    @media (--viewportSmall) {
      margin: 0px 0 44px 0;
    }

    @media (--viewportMedium) {
      margin: 0px 0 44px 0;
    }

    & > h2 {
      font-weight: var(--fontWeightSemiBold);
      font-size: 20px;
      line-height: 24px;
      color: var(--colorBlack);
      text-align: center;
      margin: 0 0 10px 0;
      padding: 0;

      @media (min-width: 480px) {
        font-size: 24px;
        line-height: 30px;
      }

      @media (--viewportSmall) {
        font-size: 30px;
        line-height: 38px;
      }

      @media (--viewportMedium) {
        font-size: 36px;
        line-height: 48px;
      }
    }
    & > p {
      padding: 0;
      margin: 0;
      font-size: 16px;
      font-weight: var(--fontWeightRegular);
      color: var(--colorGrey600);
    }

    & .viewsidebarcontentMobile {
      text-align: center;
      margin-top: 6px;

      & > span {
        & > svg {
          width: 20px;
          cursor: pointer;
          margin-left: 4px;
          position: relative;
          bottom: 1px;
        }
      }
    }
  }

  & .formRow {
    display: flex;
    flex-direction: column;

    @media (--viewportSmall) {
      flex-direction: row;
    }

    &:last-child {
      & .formFld {
        margin-bottom: 0;
      }
    }

    &.fullWidth {
      flex-direction: column;

      & .formFld {
        width: 100%;
        margin-right: 0;
      }

      & .note {
        margin: 0;
        font-weight: var(--fontWeightMedium);
        font-size: 11px;
        line-height: 16px;
        letter-spacing: -0.2px;
        color: #888888;
      }
    }

    &.nameRow {
      & .formFld {
        width: calc(100% / 1);

        @media (--viewportSmall) {
          width: calc(100% / 2);
        }

        &:first-child {
          width: 100%;

          @media (--viewportSmall) {
            width: 26%;
          }
        }
      }
    }

    & .formFld {
      width: 100%;
      margin-bottom: 24px;
      position: relative;

      @media (--viewportSmall) {
        width: calc(100% / 2 - 12px);
        margin-right: 24px;
      }

      &:last-child {
        margin-right: 0;
      }

      & input,
      & select {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.304706px;
        color: #4a4a4a;
        min-height: 41px;
        box-shadow: none;

        &::placeholder {
          opacity: 1;
          color: #4a4a4a;
        }
      }

      @media (max-width: 543px) {
        .voucherInput input {
          font-size: 10px;
        }

        .verifyButton {
          font-weight: 300;
          font-size: 10px;
        }
      }

      & .showHidePass {
        position: absolute;
        right: 12px;
        z-index: 1;
        top: 6px;
        cursor: pointer;

        & > svg {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
}

.sidebarMobileContainer {
  text-align: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 0;
  height: 100%;

  background-color: rgb(0 0 0 / 51%);

  &.showContent {
    width: 100%;
    background-color: rgb(0 0 0 / 51%);

    & .sidebarContent {
      max-width: 100%;
      width: 100%;
    }
  }

  & .sidebarContent {
    background-color: var(--colorWhite);
    padding: 50px 24px;
    position: relative;
    text-align: left;
    overflow-y: scroll;
    height: 100vh;
    width: 100%;

    @media (--viewportMedium) {
      padding: 100px 50px;
    }

    @media (min-width: 1200px) {
      max-width: 527px;
    }

    & .close {
      font-size: 40px;
      line-height: 20px;
      font-weight: 300;
      color: var(--colorBlack);
      cursor: pointer;
      transition: var(--transitionStyle);
      position: fixed;
      top: 20px;
      right: 20px;

      &:hover {
        transition: var(--transitionStyle);
        color: var(--marketplaceColor);
      }
    }

    & .sidebarHead {
      margin-bottom: 60px;

      & > h2 {
        font-weight: 700;
        font-size: 22px;
        line-height: 28px;
        letter-spacing: -0.75px;
        color: #1d1d1f;
        margin: 0 0 10px 0;
        padding: 0;
      }

      & > p {
        font-weight: 400;
        font-size: 13px;
        line-height: 20px;
        color: var(--colorBlack);
        margin: 0px;
        padding: 0;
      }
    }

    & .sidebarBody {
      & .howitworks {
        margin-bottom: 100px;
      }

      & .howitworks,
      & .registerAsEmployee {
        & > h2 {
          font-weight: 800;
          font-size: 20px;
          line-height: 24px;
          letter-spacing: -0.348235px;
          color: #4a4a4a;
          margin: 0 0 20px 0;
          padding: 0;
        }

        & > p {
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: -0.348235px;
          color: #4a4a4a;
          margin: 0 0 20px 0;
          padding: 0;

          &:last-child {
            margin-bottom: 0;
          }
        }

        & > ul {
          padding-left: 20px;
          list-style-type: disc;
          margin: 0 0 20px 0;

          & > li {
            padding: 0;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: -0.348235px;
            color: #4a4a4a;
            margin: 0 0 10px 0;

            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
}

.registerHere {
  & > a {
    margin-left: 4px;
  }
}

.typeOptions {
  display: flex;
  justify-content: center;
  gap: 14px;
  margin-bottom: 48px;
  width: 100%;

  & > span {
    width: calc(100% / 2);
    & > input:checked + label,
    & > input:hover + label {
      transition: var(--transitionStyle);
      border-color: var(--marketplaceColor);
      background-color: var(--colorGrey50);
      cursor: pointer;
      & > svg {
        & path {
          stroke: var(--marketplaceColor);
        }
      }
    }
    & > input:hover + label {
      transition: var(--transitionStyle);
      border-color: var(--colorGrey600);
      background-color: var(--colorWhite);
      cursor: pointer;
      & > svg {
        & path {
          stroke: var(--colorGrey600);
        }
      }
      & > span {
        color: var(--colorGrey600) !important;
      }
    }
    & > label {
      transition: var(--transitionStyle);
      border: solid 1px var(--colorGrey100);
      background-color: var(--colorWhite);
      padding: 24px 16px;
      border-radius: var(--borderRadiusLarge);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;
      @media (--viewportMedium) {
        padding: 32px 24px;
      }
      & > span {
        padding: 0;
        margin: 0;
        color: var(--colorGrey500);
        &:first-child {
          display: none;
        }
      }
      & > svg {
        width: 30px;
        height: 30px;
        margin-bottom: 10px;
        & path {
          stroke: var(--colorGrey500);
        }
      }
    }
  }
}

.error {
  position: relative;
  bottom: 12px;
  left: 5px;
  font-size: 14px;
  color: #d70000;
  font-weight: 600;
}

.dateOfBirth {
  font-size: 12px;
  margin-bottom: 8px;
  font-weight: 600;
}

.successWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  padding: 10px;
  width: 100%;
  background-color: rgb(46 204 113 / 10%);
  border: solid 1px rgb(196 228 210);
  border-radius: var(--borderRadius);
  & .success {
    padding: 0;
    margin: 0;
    font-size: 13px;
    color: var(--colorSuccess);
  }
  & .clearButton {
    margin: 0 0 0 8px;
    line-height: 120%;
    padding: 0;
    cursor: pointer;

    & > svg {
      width: 16px;
      height: 16px;
      & > path {
        fill: white;
      }
    }
  }
}

.voucherError {
  color: var(--colorFail);
  font-size: 13px;
  line-height: 120%;
}

.iconSpinner {
  display: flex;
  justify-content: end;
  & > svg {
    width: 20px;
    height: 20px;
  }
}
.voucherFld {
  position: relative;
  & .iconSpinner,
  & .verifyButton {
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
    font-size: 13px;
    line-height: 120%;
    z-index: 1;
    cursor: pointer;
    color: red;
    font-weight: 800;
  }
}
